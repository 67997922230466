import React from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"

type FooterProps = {
  layoutData: any
}

const Footer = ({ layoutData }: FooterProps) => {
  const footerData = layoutData.allContentfulLayout.nodes[0]
  const navData = layoutData.allContentfulSubPage.nodes

  return (
    <footer className="bg-black py-8 lg:pt-8 lg:pb-11">
      <nav className="container flex justify-between items-center">
        <Link className="lg:hidden" to="/">
          <img
            src={footerData.mobileLogo.url}
            className="max-h-14"
            alt="Captive Reinsurance Solutions Logo"
          />
        </Link>
        <Link className="hidden lg:block" to="/">
          <img
            src={footerData.footerLogo.url}
            alt="Captive Reinsurance Solutions Logo"
            style={{ maxHeight: "55px" }}
          />
        </Link>
        <div className="flex flex-col lg:w-1/2 lg:flex-row lg:items-center">
          <ul className="flex flex-col text-white lg:flex-row lg:items-center">
            {navData.map((item, idx) => (
              <li key={idx}>
                <Link
                  to={`/${item.slug}`}
                  className="text-sm font-medium lg:px-6"
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
          <p className="text-sm font-medium text-white lg:ml-auto">
            © {new Date().getFullYear()}
          </p>
        </div>
      </nav>
    </footer>
  )
}

export default injectIntl(Footer)
