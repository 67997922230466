import React, { ReactNode } from "react"

import Header from "./header"
import Footer from "./footer"

type LayoutProps = {
  children: ReactNode
  layoutData: any
}

const Layout = ({ children, layoutData }: LayoutProps) => {
  return (
    <>
      <Header layoutData={layoutData} />
      <main>{children}</main>
      <Footer layoutData={layoutData} />
    </>
  )
}

export default Layout
