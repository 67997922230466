import React, { ReactNode } from "react"
import { injectIntl, Link, useIntl } from "gatsby-plugin-intl"
import LanguageSwitcher from "../lang-switcher"

type MailtoProps = {
  className: string
  email: string
  subject: string
  body: string
  children: ReactNode
}
const Mailto = ({ className, email, subject, body, children }: MailtoProps) => {
  return (
    <a
      className={className}
      href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}
    >
      {children}
    </a>
  )
}

const Header = ({ layoutData }: any) => {
  const intl = useIntl()
  const headerData = layoutData.allContentfulLayout.nodes[0]

  return (
    <header>
      <nav className="bg-black py-4">
        <div className="container flex justify-between items-center">
          <Link to="/">
            <img
              src={headerData.logo.url}
              className="hidden md:block"
              alt="Captive Reinsurance Solutions Logo"
              style={{ maxHeight: "46px" }}
            />
            <img
              src={headerData.mobileLogo.url}
              className="max-h-11 md:hidden"
              alt="Captive Reinsurance Solutions Logo"
            />
          </Link>
          <div className="flex flex-row">
            <LanguageSwitcher />
            <Mailto
              className="py-2 px-6 text-white text-lg font-display border-[1px] border-white drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]"
              email={headerData.contactMail}
              subject={headerData.contactSubject}
              body={headerData.contactBody.contactBody}
            >
              {intl.formatMessage({ id: "contact_btn" })}
            </Mailto>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default injectIntl(Header)
