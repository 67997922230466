import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"

const languageName = {
  "en-US": "EN",
  de: "DE",
}

const LanguageSwitcher = () => {
  return (
    <ul className="flex items-center text-white mr-3 md:mr-6">
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
          languages.map(language => (
            <li className="lang-item" key={language}>
              <a
                onClick={() => changeLocale(language)}
                className={`text-base font-display cursor-pointer lg:text-lg ${
                  currentLocale === language ? "underline" : ""
                }`}
              >
                {languageName[language]}
              </a>
              <span className="text-lg px-1 font-display">/</span>
            </li>
          ))
        }
      </IntlContextConsumer>
    </ul>
  )
}

export default LanguageSwitcher
