import React from "react"
import { graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { INLINES, BLOCKS } from "@contentful/rich-text-types"
import Layout from "../components/layout"

const options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      const { uri } = node.data
      return (
        <a href={uri} className="text-sm font-medium underline">
          {children}
        </a>
      )
    },
    [BLOCKS.HEADING_1]: (node, children) => {
      return <h1 style={{margin: "8px 0"}}>{children}</h1>
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      return <h2>{children}</h2>
    },
    [BLOCKS.HEADING_3]: (node, children) => {
      return <h3>{children}</h3>
    },
    [BLOCKS.HR]: (node, children) => {
      return <hr style={{margin: "16px 0"}}/>
    },
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul>
        {children}
      </ul>
    ),
  },
}

const SubPage = ({ data }) => {
  return (
    <Layout layoutData={data}>
      <div className="container rich-text">{renderRichText(data.contentfulSubPage.content, options)}</div>
    </Layout>
  )
}

export const query = graphql`
  query SubpageQuery($slug: String, $locale: String) {
    contentfulSubPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      slug
      name
      content {
        raw
      }
    }
    allContentfulSubPage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        slug
        name
      }
    }
    allContentfulLayout(filter: { node_locale: { eq: $locale } }) {
      nodes {
        logo {
          url
        }
        mobileLogo {
          url
        }
        footerLogo {
          url
        }
        contactMail
        contactSubject
        contactBody {
          contactBody
        }
      }
    }
  }
`

export default SubPage
